import clsx from 'clsx';
import Enllac from 'components/3cat/Enllac';
import Icona from 'components/3cat/ui/Icona';
import __isEmpty from 'lodash/isEmpty';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/free-mode';
import { dataResources } from 'utils/constants';
import MenuBarra from './Menu.barra';
import MenuDinamic from './Menu.dinamic';
import MenuEstatic from './Menu.estatic';
import MenuLogin from './MenuLogin';
import styles from './menu.module.scss';
import { itemsMenuEstatic } from './menuEstatic';

const { basePath } = dataResources;

const imageLoader = ({ src }) => {
    return `${src}`;
};

export default function Menu(props) {
    const {
        tema,
        seccio,
        titol,
        items,
        menuReduit,
        nombonic,
        estil,
        mostraH1,
        type,
        tipus,
        mesInfo,
        urlApi,
        numAudios,
        numCapitols,
        audioPub,
        videoPub,
        requiredServices
    } = props;

    const slug = nombonic;
    const [openBurguer, setOpenBurguer] = useState(false);
    const [openSideExplora, setOpenSideExplora] = useState(false);
    const [isSideMenuBottom, setIsSideMenuBottom] = useState(false);
    const [isMenuDark, setIsMenuDark] = useState(false);
    const Etiqueta = mostraH1 === true ? `${'h1'}` : `${'div'}`;
    const LogoTag = seccio === 'home' && tipus === '' ? `${'h1'}` : `${'div'}`;
    const refExplora = useRef();
    const refSidemenu = useRef();
    const overflowWrapper = useRef();
    const refSubMenufocus = useRef();
    const refMenuButton = useRef();

    const stylesMenu = clsx({
        [styles.menu]: true,
        [styles.dark]: isMenuDark,
        [styles[estil]]: !__isEmpty(estil),
        [styles[tema]]: !__isEmpty(tema),
        [styles.reduit]: menuReduit ? (!__isEmpty(titol) ? true : false) : false,
        [styles.mobilReduit]: !__isEmpty(titol)
    });

    const stylesMenuWrapper = clsx({
        [styles.menuWrapper]: true,
        [styles.menuWrapperGradient]: menuReduit
    });

    const stylesSideMenu = clsx({
        [styles.sideMenu]: true,
        [styles.bottom]: isSideMenuBottom
    });

    const stylesMask = clsx({
        [styles.mask]: true,
        [styles.openBurguer]: openBurguer
    });

    const stylesSubMenu = clsx({
        [styles.submenu]: true,
        [styles[tema]]: !__isEmpty(tema),
        [styles.reduit]: menuReduit ? (!__isEmpty(titol) ? true : false) : false,
        [styles.mobilReduit]: !__isEmpty(titol),
        [styles.programa]: tipus === 'programa' ? true : false
    });

    const stylesSideExplora = clsx({
        [styles.open]: openSideExplora
    });

    const stylesLogo = clsx({
        [styles.logo]: true
    });

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!refSidemenu?.current?.contains(event.target)) {
                setOpenBurguer(false);
                refSidemenu.current.setAttribute('inert', true);
            }
        };
        document.addEventListener('mousedown', handleClickOutside, { passive: true });
        document.addEventListener('touchstart', handleClickOutside, { passive: true });

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [refExplora, refSidemenu]);

    useEffect(() => {
        const updateMenuDark = (event) => {
            const scrollColourChange = window.innerWidth / 20 > 100 ? 100 : window.innerWidth / 20;
            setIsMenuDark(window.scrollY > scrollColourChange);
        };
        window.addEventListener('scroll', updateMenuDark);
        return () => {
            window.removeEventListener('scroll', updateMenuDark);
        };
    }, []);

    const handleScroll = () => {
        setIsSideMenuBottom(
            overflowWrapper.current.scrollHeight - overflowWrapper.current.offsetHeight === overflowWrapper.current.scrollTop
        );
    };

    const openMenuBurguer = () => {
        handleScroll();
        refSidemenu.current.removeAttribute('inert');
        refSubMenufocus.current.focus();
        setOpenBurguer(true);
    };

    const closeMenuBurguer = () => {
        refSidemenu.current.setAttribute('inert', true);
        refMenuButton.current.focus();
        setOpenBurguer(false);
    };

    //MENU HAMBURGUER
    const _buildSubmenuOpcio = (element, i) => {
        return (
            <li key={`menu-${i}-${element.id}`} data-id={element.id}>
                <Enllac type={element.type} id={element.id} slug={slug} slugRelacio={element.tag_friendly}>
                    {element.text || element.icona_alt}
                </Enllac>
            </li>
        );
    };

    const _iterateSubmenuOpcions = (opcions) => {
        if (!__isEmpty(opcions)) {
            return Array.from(opcions).map((element, i) => _buildSubmenuOpcio(element, i));
        }
        return <></>;
    };

    const _iterateSidemenuItem = (element, i) => {
        if (__isEmpty(element.opcions)) {
            return (
                <li key={`menu-${i}-${element.id}`}>
                    <Enllac
                        type={element.type}
                        id={element.id}
                        slug={slug}
                        slugRelacio={element.tag_friendly}
                        className={styles.ambIcona}
                    >
                        <span>{!__isEmpty(element.text) && element.text}</span>
                        {!__isEmpty(element.icona) && (
                            <span className={styles.opcioAmbIcona}>
                                <Icona icona={element.icona} alt={element.icona_alt} mida={20} ariaHidden={true} />
                            </span>
                        )}
                    </Enllac>
                </li>
            );
        } else {
            return (
                <li className={stylesSideExplora} key={`menu-${i}-${element.id}`}>
                    <button className={styles.dropButton} onClick={() => setOpenSideExplora(!openSideExplora)}>
                        <span>
                            explora <span className='sr-only'>més seccions</span>
                        </span>
                        <Icona icona='fletxa_simple_avall' alt='desplega' className={styles.ico_menuDesplega} />
                    </button>
                    <ul className={styles.sideSubmenu}>{_iterateSubmenuOpcions(element.opcions)}</ul>
                </li>
            );
        }
    };

    const _buildMenuEstatic = (itemsMenuEstatic) => {
        if (!__isEmpty(itemsMenuEstatic)) {
            return Array.from(itemsMenuEstatic.item).map((element, i) => _iterateSidemenuItem(element, i));
        }
    };

    const _showMenu = () => {
        if (__isEmpty(items) && tipus !== 'dinamic') {
            return <MenuEstatic seccio={seccio} slug={slug} />;
        } else if (tipus === 'dinamic' && slug) {
            return (
                <MenuDinamic
                    items={!items ? [] : items}
                    mostraH1={mostraH1}
                    slug={slug}
                    titol={titol}
                    seccio={seccio}
                    type={type}
                    mesInfo={mesInfo}
                    urlApi={urlApi}
                    medias={{
                        audios: numAudios,
                        capitols: numCapitols,
                        audioPub,
                        videoPub
                    }}
                />
            );
        } else {
            return <></>;
        }
    };

    return (
        <>
            <a href='#navegacio' accessKey='1' className='sr-only'>
                Anar a la navegació principal
            </a>
            <a href='#contingut' className='sr-only' accessKey='c'>
                Anar al contingut
            </a>
            <MenuBarra />
            <div className={stylesMenu} data-testid='menu'>
                <div className={stylesMenuWrapper}>
                    <button
                        ref={refMenuButton}
                        onClick={() => {
                            openMenuBurguer();
                        }}
                        className={styles.burguer}
                    >
                        <Icona
                            icona='menu'
                            alt='Icona menu'
                            ariaHidden={true}
                            className={styles.ico_boto}
                            mida='20'
                            inline={true}
                        />
                        <span className='sr-only'>Obre menú de navegació</span>
                    </button>
                    <Enllac href='/3cat/'>
                        <LogoTag className={stylesLogo}>
                            <Image
                                loader={imageLoader}
                                unoptimized={true}
                                width={111}
                                height={32}
                                alt='Logo 3Cat'
                                src={basePath + '/_resources/logos/3cat_color.svg?v=1.2503.10'}
                            />
                        </LogoTag>
                    </Enllac>

                    {titol != '' && estil !== 'paginaPrograma' && <Etiqueta className={styles.titol}>{titol}</Etiqueta>}
                    <div className={styles.elementsDreta}>
                        <Enllac href='/3cat/cercador/ ' className={styles.botoCerca}>
                            <Icona
                                icona={seccio === 'cercador' ? 'cercador_ple' : 'cercador'}
                                mida='22'
                                alt='Cerca'
                                className={styles.iconaCerca}
                                inline={true}
                            />
                        </Enllac>
                        <MenuLogin requiredServices={requiredServices} />
                    </div>
                </div>
            </div>
            <div className={stylesSubMenu}>{_showMenu()}</div>
            <div className={stylesMask}>
                <div className={stylesSideMenu} ref={refSidemenu} id='navegacio' inert='true'>
                    <div className={styles.header}>
                        <button className={styles.tanca} onClick={() => closeMenuBurguer()} ref={refSubMenufocus}>
                            <Icona icona='tanca' className={styles.ico_tanca} alt='Tanca' ariaHidden={true} />
                            <span className='sr-only'>Tanca menú de navegació</span>
                        </button>
                        <Enllac href='/3cat/'>
                            <Image
                                className={stylesLogo}
                                loader={imageLoader}
                                unoptimized={true}
                                width={111}
                                height={32}
                                alt='Logo 3Cat'
                                src={basePath + '/_resources/logos/3cat.svg?v=1.2503.10'}
                            />
                        </Enllac>
                    </div>
                    <div className={styles.overflowWrapper} ref={overflowWrapper} onScroll={handleScroll}>
                        <ul className={styles.menuSeccions}>{_buildMenuEstatic(itemsMenuEstatic)}</ul>
                        <div className={styles.footer}>
                            <div className={styles.footerCanals} aria-label='Canals'>
                                <Enllac href='https://www.3cat.cat/3cat/' className={styles.footerLogo3cat}>
                                    <Image
                                        loader={imageLoader}
                                        unoptimized={true}
                                        width={96}
                                        height={28}
                                        alt='Logo 3Cat'
                                        src={basePath + '/_resources/logos/3cat_color.svg?v=1.2503.10'}
                                    />
                                </Enllac>
                                <Enllac href='https://www.3cat.cat/tv3/'>
                                    <Image
                                        loader={imageLoader}
                                        unoptimized={true}
                                        width={33}
                                        height={28}
                                        alt='Logo TV3'
                                        src={basePath + '/_resources/logos/tv3.svg?v=1.2503.10'}
                                    />
                                </Enllac>
                                <Enllac href='https://www.3cat.cat/catradio/'>
                                    <Image
                                        loader={imageLoader}
                                        unoptimized={true}
                                        width={34}
                                        height={30}
                                        alt='Logo Catalunya Ràdio'
                                        src={basePath + '/_resources/logos/cr.svg?v=1.2503.10'}
                                    />
                                </Enllac>
                                <Enllac href='https://www.3cat.cat/tv3/sx3/'>
                                    <Image
                                        loader={imageLoader}
                                        unoptimized={true}
                                        width={44}
                                        height={22}
                                        alt='Logo SX3'
                                        src={basePath + '/_resources/logos/sx3.svg?v=1.2503.10'}
                                    />
                                </Enllac>
                                <Enllac href='https://www.3cat.cat/3cat/eva/'>
                                    <Image
                                        src={basePath + '/_resources/logos/eva.svg?v=1.2503.10'}
                                        loader={imageLoader}
                                        unoptimized={true}
                                        width={47}
                                        height={21}
                                        alt='Logo EVA'
                                    ></Image>
                                </Enllac>
                            </div>
                            <ul className={styles.footerSeccions} aria-label='Portals de la CCMA'>
                                <li>
                                    <Enllac href='https://www.3cat.cat/324/'>Notícies</Enllac>
                                </li>
                                <li>
                                    <Enllac href='https://www.3cat.cat/esport3/'>Esports</Enllac>
                                </li>
                            </ul>
                            <ul className={styles.footerSeccions} aria-label='Enllaços'>
                                <li className={styles.textLight}>
                                    <Enllac href='https://www.3cat.cat/corporatiu/ca/el-grup/'>Corporatiu</Enllac>
                                </li>
                                <li className={styles.textLight}>
                                    <Enllac href='https://botiga.3cat.cat'>Botiga</Enllac>
                                </li>
                                <li className={styles.textLight}>
                                    <Enllac type='web_3cat_seccio' id='cercador' className={styles.cercador_link}>
                                        <span>Cerca</span>
                                        <Icona className={styles.ico_cerca} icona='cercador' alt='Icona cerca' mida={16} />
                                    </Enllac>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div id='contingut' className='sr-only'></div>
        </>
    );
}

Menu.defaultProps = {
    tema: '',
    titol: '',
    mostraH1: false,
    menuReduit: false,
    tipus: ''
};
